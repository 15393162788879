import React from 'react';
import {
  Box,
  Typography,
} from '@mui/material';
import Header from '../Header/Header';
import theme from '../theme';

const AboutUs = () => {

/*CSS Classes*/
const root = {
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
};
const sectionArrangement = {
  display: 'flex', 
  flexDirection: 'column',
};
const summaryArrangement = {
  display: 'flex',
  flexDirection: 'column',
  background: theme.palette.secondary.main,
  backgroundSize: 'cover',
  backgroundPositionY: '1%',
  justifyContent: 'center',
  minHeight: '129px',
};
const summaryHeading = {
  color: 'white',
  textAlign: 'center',
  margin: '1% 0px',
  fontSize: '3.45rem',
};
const changingContainerHeaderTwo = {
  textAlign: 'center',
  margin: '2.4% 1% 1% 1%',
  color: theme.palette.secondary.main,
  fontSize: '52px',
  [theme.breakpoints.down(1120)]: {
    fontSize: '50px',
  },
  [theme.breakpoints.down(696)]: {
    fontSize: '40px',
  },
  [theme.breakpoints.down(600)]: {
    fontSize: '35px',
  },
};
const aboutText = {
  fontSize: '1.5rem',
  lineHeight: '2',
  textAlign: 'center',
  padding: '1% 10%',
  [theme.breakpoints.down(1120)]: {
    fontSize: '1.75rem',
  },
  [theme.breakpoints.down(696)]: {
    fontSize: '1.5rem',
  },
  [theme.breakpoints.down(600)]: {
    fontSize: '1.10rem',
  },
};
/*End of CSS Classes*/

  return (
    <Box sx={root}>
      <Header />
      <Box sx={summaryArrangement}>
        <Typography sx={summaryHeading}>
          About Us
        </Typography>
      </Box>
      <Box sx={sectionArrangement}>
        <Typography sx={changingContainerHeaderTwo}>
          What is The Purpose of LegislativeHub?
        </Typography>
        <Typography sx={aboutText}>
          LegislativeHub's mission is to provide essential information to those who advocate for effective laws and regulations in state and local government. Our number one focus is you, our customer. Our work is centered around you receiving accurate and timely information. LegislativeHub will continue to build upon the high standards of integrity and customer care that Georgia Lobby (founding company) has delivered since 2003.
        </Typography>
      </Box>
    </Box>
  );
};

export default AboutUs;
