import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  AppBar, Button, ListItem, Link, Divider,
  Box, Drawer, List, ListItemText, IconButton,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import useUser from '../utils/useUser';
import { getAuthClient } from '../utils/auth';
import theme from '../theme';

const Header = (props) => {
  const { user } = useUser();
  const { currentPage } = props;
  const auth = getAuthClient();
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const [currentUser, setCurrentUser] = useState(null);
  const [userLoggedIn, setUserLoggedIn] = useState(false);

  const appBar = {
    backgroundColor: 'transparent',
    minHeight: '8vh',
    justifyContent: 'end',
    display: 'flex',
    flexDirection: 'row',
    padding: '0px',
    boxShadow: 'none',
    position: 'relative',
    borderBottomStyle: currentPage === 'home' ? 'none' : 'inset',
    [theme.breakpoints.down(1170)] : {
      display: 'none',
    },
  };
  const secondBar = {
    backgroundColor: 'transparent',
    minHeight: '10vh',
    justifyContent: 'space-between',
    display: 'flex',
    flexDirection: 'row',
    padding: '0px 1% 0px 1%',
    boxShadow: 'none',
    position: 'relative',
    borderBottomStyle: currentPage === 'home' ? 'none' : 'inset',
  };
  const dockerAppearance = {
    display: 'none',
    [theme.breakpoints.down(1170)] : {
      display: 'flex',
    },
    '& .MuiPaper-root': {
      width: '30%',
      backgroundColor: theme.palette.primary.main,
      [theme.breakpoints.down(940)]: {
        width: '45%',
      },
    },
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const getUser = async () => {
    if (!currentUser) {
      const url = `/hub_user`;
      const dataOptions = {
        method: 'GET',
        headers: new Headers({
          'Accept': 'application/vnd.api+json',
          'Content-Type': 'application/json'
        }),
      };

      const data = await auth.fetchWithAuthentication(url, dataOptions);
      const jsonData = await data.json();

      if (jsonData.status !== 200) {
        setCurrentUser('not in');
      } else {
        setCurrentUser(jsonData.current_user);
        setUserLoggedIn(true);
      }
    }
  };

  useEffect(() => {
    if (!userLoggedIn && !currentUser && currentUser !== 'not in') {
      getUser();
    }
  });

  return (
    <Box style={{ display: 'flex', flexDirection: 'column', position: currentPage === 'home' ? 'absolute' : 'relative', width: '100%' }}>
      <AppBar sx={secondBar}>
        <Box sx={{ display: 'flex', marginRight: '3.75%', flexDirection: 'row', width: '100%', justifyContent: 'space-between' }}>
          <Box sx={{ height: '33px', alignSelf: 'center', marginLeft: '1%' }}>
            <img style={{height: '100%'}} alt="LegislativeHub" src={`${process.env.PUBLIC_URL}/ColorLogoStraight.png`}/>
          </Box>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              ...(open && { display: 'none' }), color: 'lightgray',
              margin: 0,
              marginLeft: '1%',
              [theme.breakpoints.up(1170)] : {
                display: 'none',
              },
            }}
          >
            <MenuIcon fontSize="large" />
          </IconButton>
        </Box>
        <Box
          sx={{
            display: 'flex', justifyContent: 'end',
            width: '100%', [theme.breakpoints.down(1170)] : {
              display: 'none',
            },
          }}
        >
          <Button
            sx={{
              height: '100%', color: 'lightgray', borderRadius: '0px', width: '18.5%', fontSize: '1rem',
            }}
            href="/"
          >
            Home
          </Button>
          <Button
            sx={{
              color: 'lightgray', borderRadius: '0px', height: '100%', width: '22.5%', fontSize: '1rem',
            }}
            href="/services" 
          >
            Services
          </Button>
          <Button
            sx={{
              color: 'lightgray', borderRadius: '0px', height: '100%', width: '22.5%', fontSize: '1rem',
            }}
            href="/aboutus" 
          >
            About Us
          </Button>
          <Button
            sx={{
              color: 'lightgray', borderRadius: '0px', height: '100%', width: '22.5%', fontSize: '1rem',
            }}
            // onClick={() => changeCurrentPage('contact us')}  
            href="/contact"
          >
            Contact Us
          </Button>
        </Box>
      </AppBar>
      <AppBar sx={appBar}>
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <Drawer
            sx={dockerAppearance}
            variant="temporary"
            anchor="left"
            open={open}
          >
            <div>
              <IconButton onClick={handleDrawerClose}>
                <ChevronLeftIcon />
              </IconButton>
            </div>
            <Divider />
            <List>
              <ListItem button component={Link} to="/" key="Home" onClick={()=>navigate("/")}>
                <ListItemText sx={{ color: 'white' }}>
                  Home
                </ListItemText>
              </ListItem>
              <ListItem button component={Link} key="Services" onClick={()=>navigate("/services")}>
                <ListItemText sx={{ color: 'white' }}>
                  Services
                </ListItemText>
              </ListItem>
              <ListItem button component={Link} key="About Us" onClick={()=>navigate("/aboutus")}>
                <ListItemText sx={{ color: 'white' }}>
                  About Us
                </ListItemText>
              </ListItem>
              <ListItem component={Link} key="Contact" onClick={()=>navigate("/contact")}>
                <ListItemText
                  href="/contact"
                  sx={{ color: 'white' }}
                >
                  Contact Us
                </ListItemText>
              </ListItem>
            </List>
            <Divider />
            <List>
              <ListItem component={Link}
                onClick={(!user) ? 
                  ()=>navigate("/") :
                  ()=>{
                    auth.logout();
                    navigate("/");
                  }
                }
                >
                {(!user && (!currentUser || currentUser === 'not in')) && (
                  <ListItemText sx={{ color: 'white' }}>
                    Sign In
                  </ListItemText>
                )}
                {(user && currentUser && currentUser !== 'not in') && (
                  <ListItemText sx={{ color: 'white' }}>
                    Sign Out
                  </ListItemText>
                )}
              </ListItem>
              <ListItem>
                {(!user && (!currentUser || currentUser === 'not in')) && (
                  <ListItemText sx={{ color: currentPage === 'home' ? 'white' : theme.palette.primary.main }}>
                    Register
                  </ListItemText>
                )}
              </ListItem>
            </List>
          </Drawer>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'end', width: '50%', marginRight: '3%', padding: '0px' }}>
          {(!user || (currentUser === 'not in' || !currentUser)) ?
            (
              <Button
                href="/"
                sx={{
                  alignSelf: 'center', borderRadius: '9px', color: 'white', marginRight: '2%',
                }}
                variant="contained"
                color="secondary"
              >
                Sign In
              </Button>
            ) : (
            <Button
              onClick={() => {auth.logout()}}
              sx={{
                alignSelf: 'center', borderRadius: '9px',
                color: 'white', marginRight: '2%',
              }}
              color="primary"
              variant="contained"
              href="/"
            >
              Logout
            </Button>
          )}
          {(user && currentUser && currentUser !== 'not in') && (
            <Button
              href="/settings"
              sx={{
                color: 'white',
                [theme.breakpoints.down(1170)] : {
                  display: 'none',
                },
              }}
            >
              Go To App
            </Button>
          )}
          {(!user || (!currentUser || currentUser === 'not in')) && (
            <Button
              href="/register59235070"
              sx={{
                [theme.breakpoints.down(1170)] : {
                  display: 'none',
                },
                color: currentPage === 'home' ? 'white' : theme.palette.secondary.secondary,
              }}
            >
              Register
            </Button>
          )}
        </Box>
      </AppBar>
    </Box>
  );
};

export default Header;
