import React, { useState, useEffect } from 'react';
import {
  Box, Button, IconButton, Typography,
} from '@mui/material';
import theme from '../theme';
import { getAuthClient } from '../utils/auth';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPaste';
import TuneIcon from '@mui/icons-material/Tune';
import GavelIcon from '@mui/icons-material/Gavel';
import AssuredWorkloadIcon from '@mui/icons-material/AssuredWorkload';

const DashboardHome = (props) => {
  const { currentPage } = props;
  
  const auth = getAuthClient();
  const [currentUser, setCurrentUser] = useState(null);

  const getUser = async () => {
    if (!currentUser) {
      const url = `/hub_user`;
      const dataOptions = {
        method: 'GET',
        headers: new Headers({
          'Accept': 'application/vnd.api+json',
          'Content-Type': 'application/json'
        }),
      };

      const data = await auth.fetchWithAuthentication(url, dataOptions);
      const jsonData = await data.json();
      setCurrentUser(jsonData.current_user);
    }
  };

  useEffect(() => {
    if (!currentUser) {
      getUser();
    }
  });

  const dashboardMenuStyling = {
    width: '7.5%',
    backgroundColor: '#f9f9f9',
    minHeight: '100vh',
    display: 'flex',
    borderRight: 'inset',
    alignItems: 'center',
    flexDirection: 'column',
    [theme.breakpoints.down(980)]: {
      display: 'none',
    },
  };
  const tabStyle = {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'center',
  };
  const dashTabStyle = {
    color: theme.palette.primary.main,
    margin: '2% 11%',
    fontSize: '1.15rem',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down(1363)]: {
      fontSize: '1rem',
    },
    [theme.breakpoints.down(1200)]: {
      fontSize: '0.8rem',
    },
  };
  const dashManagementTabStyle = {
    color: theme.palette.primary.main,
    display: 'flex',
    flexDirection: 'column',
    margin: '2% 7%',
    fontSize: '0.75rem',
  };
  const tabLogoutStyle = {
    position: 'fixed',
    bottom: 0,
    margin: '1%',
  };
  const iconSize = {
    fontSize: '2rem',
  };
  const typographyStyle = {
    fontSize: '0.6rem',
  };
  
  return (
    <Box sx={dashboardMenuStyling}>
      <Box sx={{ width: '77%', alignSelf: 'center', marginTop: '6%', position: 'sticky', top: '3%' }}>
        <img style={{ width: '100%' }} src={`${process.env.PUBLIC_URL}/ColorLogo.png`} alt="legislative-hub-logo" />
      </Box>
      <Box sx={tabStyle} style={{ position: 'sticky', top: '16%' }}>
        <IconButton href="/settings" sx={dashTabStyle} style={{ color: (currentPage === 'settings') ? theme.palette.primary.main : 'darkgray' }}>
          <TuneIcon sx={iconSize} />
          <Typography sx={typographyStyle}>
            Settings
          </Typography>
        </IconButton>
      </Box>
      <Box sx={tabStyle} style={{ position: 'sticky', top: '27%' }}>
        <IconButton href="/boardwatch" sx={dashTabStyle} style={{ color: (currentPage === 'board') ? theme.palette.primary.main : 'darkgray' }}>
          <ContentPasteSearchIcon sx={iconSize} />
          <Typography sx={typographyStyle}>
            Board 
          </Typography>
        </IconButton>
      </Box>
      <Box sx={tabStyle} style={{ position: 'sticky', top: '38%' }}>
        <IconButton href="/billwatch" sx={dashTabStyle} style={{ color: (currentPage === 'bill') ? theme.palette.primary.main : 'darkgray' }}>
          <AssuredWorkloadIcon sx={iconSize} />
          <Typography sx={typographyStyle}>
            Bill 
          </Typography>
        </IconButton>
      </Box>
      <Box sx={tabStyle} style={{ position: 'sticky', top: '49%' }}>
        <IconButton href="/governmentwatch" sx={dashTabStyle} style={{ color: (currentPage === 'government') ? theme.palette.primary.main : 'darkgray' }}>
          <GavelIcon />
          <Typography sx={typographyStyle}>
            Government 
          </Typography>
        </IconButton>
      </Box>
      {(currentUser && currentUser.roles && (currentUser.roles.includes("employee") || currentUser.roles.includes("admin"))) && (
        <Box sx={{ height: '33vh', position: 'sticky', top: '55%', width: '100%' }}>
          <Box sx={tabStyle} style={{ position: 'absolute', top: '2%', width: '100%' }}>
            <Button href="/createboardwatch" sx={dashManagementTabStyle} style={{ margin: '2% 11%', color: (currentPage === 'addboard') ? theme.palette.primary.main : theme.palette.secondary.main }}>
              <ContentPasteSearchIcon />
              <Typography sx={typographyStyle}>
                Add Board 
              </Typography>
            </Button>
          </Box>
          <Box sx={tabStyle} style={{ position: 'absolute', top: '32%', width: '100%' }}>
            <IconButton href="/creategovernmentwatch" sx={dashManagementTabStyle} style={{ margin: '2% 11%', color: (currentPage === 'addgovernment') ? theme.palette.primary.main : theme.palette.secondary.main }}>
              <GavelIcon />
              <Typography sx={typographyStyle}>
                Add Government 
              </Typography>
            </IconButton>
          </Box>
          <Box sx={tabStyle} style={{ position: 'absolute', top: '58%', width: '100%' }}>
            <IconButton href="/createbillwatch" sx={dashManagementTabStyle} style={{ margin: '2% 11%', color: (currentPage === 'addbill') ? theme.palette.primary.main : theme.palette.secondary.main }}>
              <AssuredWorkloadIcon />
              <Typography sx={typographyStyle}>
                Add Bill
              </Typography>
            </IconButton>
          </Box>
          <Box sx={tabStyle} style={{ position: 'absolute', top: '86%', width: '100%' }}>
            <Button href="/manage" sx={dashManagementTabStyle} style={{ textAlign: 'center', margin: '2% 11%', color: (currentPage === 'usermanagement') ? theme.palette.primary.main : theme.palette.secondary.main }}>
              Manage Users
            </Button>
          </Box>
        </Box>
      )}
      <Box sx={tabLogoutStyle}>
        <IconButton onClick={() => {auth.logout()}} href="/" sx={dashTabStyle} style={{ color: theme.palette.secondary.secondary }}> 
          <ExitToAppIcon sx ={{ color: theme.palette.secondary.secondary }} />
          <Typography>
            Logout
          </Typography>
        </IconButton>
      </Box>
    </Box>
  );
};

export default DashboardHome;
