import React, { useEffect, useState, useMemo } from 'react';
import {
  Box, Typography, IconButton, Dialog,
  DialogActions, DialogContent, Button, DialogTitle,
  FormControl, MenuItem, InputLabel, Select,
  Alert, Fade, FormGroup, FormControlLabel,
  Checkbox,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ClearIcon from '@mui/icons-material/Clear';
import CheckIcon from '@mui/icons-material/Check';
import MaterialReactTable from 'material-react-table';
import { getAuthClient } from "../utils/auth";
import theme from '../theme';
import DashboardHome from '../RestrictedAccess/DashboardHome';
import MobileDashboard from '../RestrictedAccess/MobileDashboard';
// import { getDate } from 'date-fns';

const ManageUsers = () => {
  const [ allUsers, setAllUsers ] = useState(null);
  const [ oneUser, setOneUser ] = useState(null);
  const [ allCompanies, setAllCompanies ] = useState([]);
  const [ oneCompany, setOneCompany ] = useState(null);
  const [ openBusiness, setOpenBusiness ] = useState(false);
  const [ editViewSubscriber, setEditViewSubscriber ] = useState(false);
  const [ companyToAddUserTo, setCompanyToAddUserTo ] = useState(null);
  const [eventMonth, setEventMonth] = useState("0");
  const [eventDay, setEventDay] = useState("0");
  const [tableActionAlertsMessage, setTableActionAlertsMessage] = useState("");
  const [tableActionAlertsOn, setTableActionAlertsOn] = useState(false);
  const [tableActionAlertsStatus, setTableActionAlertsStatus] = useState("success");
  const [deleteUserId, setDeleteUserId] = useState(0);
  const [confirmCompanyDeletion, setConfirmCompanyDeletion] = useState(false);
  const [confirmUserDeletion, setConfirmUseDeletion] = useState(false);
  const [deleteCompanyId, setDeleteCompanyId] = useState(0);
  const [boardService, setBoardService] = useState(false);
  const [billService, setBillService] = useState(false);
  const [governmentService, setGovernmentService] = useState(false);
  const [ allSiteUsers, setAllSiteUsers ] = useState(null);
  const [servicesArray, setServicesArray] = useState([]);

  const [companyServicesBeingAltered, setCompanyServicesBeingAltered] = useState();
  // const todaysDate = getDate();
  // check a companies payment renewal date to the month date and year of the current day at the time

  const auth = getAuthClient();

  const root = {
    display: 'flex',
    backgroundColor: '#f9f9f9',
    flexDirection: 'row',
    width: '100%',
    minHeight: '100vh',
    [theme.breakpoints.down(980)]: {
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
  };

  const subscriberDetailsContainer = {
    display: 'flex',
    margin: '2% 0%',
    flexDirection: 'column'
  };

  const removeFromCompanyButton = {
    alignSelf: 'baseline',
    padding: 0,
    color: 'white',
    backgroundColor: 'red',
    borderRadius: 0,
    fontSize: '15px',
  };

  const userServicesFields = {
    display: 'flex',
    flexDirection: 'row',
    margin: '2% 1% 1.05% 1.75%',
  };

  const setBusinessAndOpenDialog = (thisId) => {
    getOneCompany(thisId);
    openBusinessDialog();
  };

  const setSusbcriberAndOpenDialog = (thisId) => {
    getOneUser(thisId);
    openSubscriberDialog();
  };

  const closeSubscriberDialog = () => {
    setOneUser(null);
    setCompanyToAddUserTo(null);
    setEditViewSubscriber(false);
  };

  const openSubscriberDialog = () => {
    setEditViewSubscriber(true);
  };

  const openBusinessDialog = () => {
    setOpenBusiness(true);
  };

  const closeBusinessDialog = () => {
    setOneCompany(null);
    setOpenBusiness(false);

  };

  const handleCompanySelectionForUserAddOn = (event) => {
    setCompanyToAddUserTo(event.target.value);
  };

  const getOneUser = async (thisId) => {
    const url = `/hub_subscriber?subscriber=${thisId}`;
    const dataOptions = {
      method: 'GET',
      headers: new Headers({
        'Accept': 'application/vnd.api+json',
        'Content-Type': 'application/json'
      }),
    };
    const data = await auth.fetchWithAuthentication(url, dataOptions);
    const jsonDataParse = await data.json();

    if (jsonDataParse.status === 200) {
      setOneUser(jsonDataParse.subscriber);

    }
  };

  const deleteOneUser = async (thisId) => {
    const url = `/hub_subscriber?subscriber=${thisId}`;
    const dataOptions = {
      method: 'DELETE',
      headers: new Headers({
        'Accept': 'application/vnd.api+json',
        'Content-Type': 'application/json'
      }),
    };
    setTableActionAlertsMessage("You have successfully deleted this user.");
    setTableActionAlertsOn(true);
    setTableActionAlertsStatus("success");
    const data = await auth.fetchWithAuthentication(url, dataOptions);
    const jsonDataParse = await data.json();

    if (jsonDataParse.status === '200') {
      closeUserDeletionConfirm();
    }
  };

  const deleteACompany = async (thisId) => {
    const url = `/hub_user?company_id=${thisId}`;
    const dataOptions = {
      method: 'DELETE',
      headers: new Headers({
        'Accept': 'application/vnd.api+json',
        'Content-Type': 'application/json'
      }),
    };
    setTableActionAlertsMessage("You have successfully deleted this company.");
    setTableActionAlertsOn(true);
    setTableActionAlertsStatus("success");
    const data = await auth.fetchWithAuthentication(url, dataOptions);
    const jsonDataParse = await data.json();
  };

  const updateUserServiceArray = () => {
    let thisArray = [];

    if (boardService) {
      thisArray.push('bulletin');
    }

    if (governmentService) {
      thisArray.push('government');
    }

    if (billService) {
      if (thisArray.indexOf('bulletin') < 0) {
        thisArray.push('bulletin');
      }
      thisArray.push('bill');
    }

    setServicesArray(thisArray);

    return thisArray;
  };

  const changeServicesForCompany = async (thisCompany) => {
    let thisPurpose = 'change company services';
    let thisArrayOfServices = [];
    thisArrayOfServices = updateUserServiceArray();
    
    const resourceData = {
      purpose: thisPurpose,
      company_id: thisCompany,
      services: thisArrayOfServices
    };

    const url = `/hub_user`;
    const dataOptions = {
      method: 'POST',
      headers: new Headers({
        'Accept': 'application/vnd.api+json',
        'Content-Type': 'application/json'
      }),
      body: JSON.stringify(resourceData),
    };
    const data = await auth.fetchWithAuthentication(url, dataOptions);
    const jsonDataParse = await data.json();
    if (jsonDataParse.status === 200) {
      setTableActionAlertsMessage('You have succesfully updated this company\'s services.');
      setTableActionAlertsStatus("success");
      setTableActionAlertsOn(true);
    } else {
      setTableActionAlertsMessage('An error occured while attempting to make changes to this company\'s information.');
      setTableActionAlertsStatus("error");
      setTableActionAlertsOn(true);
    }
  };

  const handleCompanyServicesChangeClose = () => {
    setCompanyServicesBeingAltered(false);
  };

  const getOneCompany = async (thisId) => {
    let thisPurpose = 'get one company';
    
    const resourceData = {
      purpose: thisPurpose,
      company_id: thisId
    };

    const url = `/hub_user`;
    const dataOptions = {
      method: 'POST',
      headers: new Headers({
        'Accept': 'application/vnd.api+json',
        'Content-Type': 'application/json'
      }),
      body: JSON.stringify(resourceData),
    };
    const data = await auth.fetchWithAuthentication(url, dataOptions);
    const jsonDataParse = await data.json();
    if (jsonDataParse.status === 200) {
      setOneCompany(jsonDataParse.company);
    } else {
      
    }
  };

  const closeCompanyDeletionConfirm = () => {
    setConfirmCompanyDeletion(false);
  };

  const addUserToCompany = async (thisEmail, thisPhoneNumber, thisCompanyName) => {
    let thisPurpose = 'add to company';
    
    const resourceData = {
      purpose: thisPurpose,
      subscriber_email: thisEmail,
      subscriber_phone: thisPhoneNumber,
      company: thisCompanyName
    };

    const url = `/hub_user`;
    const dataOptions = {
      method: 'POST',
      headers: new Headers({
        'Accept': 'application/vnd.api+json',
        'Content-Type': 'application/json'
      }),
      body: JSON.stringify(resourceData),
    };
    const data = await auth.fetchWithAuthentication(url, dataOptions);
    const jsonDataParse = await data.json();
    if (jsonDataParse.status === 200) {
      setTableActionAlertsMessage("You have successfully added this user to the specified company. If this was a mistake, remove the user from the company. Otherwise, refresh the page to see the update(s)");
      setTableActionAlertsOn(true);
      setTableActionAlertsStatus("success");
    } else {
      setTableActionAlertsMessage("There was an error while attempting to add this user to the specified company. Refresh the page, and try again");
      setTableActionAlertsOn(true);
      setTableActionAlertsStatus("error");
    }
  };

  const getAllUsers = async () => {
    let purpose = 'get all users';

    const resourceData = {
      purpose
    };

    const url = `/hub_user`;
    const dataOptions = {
      method: 'POST',
      headers: new Headers({
        'Accept': 'application/vnd.api+json',
        'Content-Type': 'application/json'
      }),
      body: JSON.stringify(resourceData),
    };
    const data = await auth.fetchWithAuthentication(url, dataOptions);
    const jsonDataParse = await data.json();

    if (jsonDataParse.status === 200 && jsonDataParse.all_users) {
      let thisObj = Object.values(jsonDataParse.all_users);
      let thisArray = [];

      // eslint-disable-next-line array-callback-return
      thisObj.map((user) => {
        if (user.uid[0].value !== 0) {
          let newObject = {
            id: user.uid[0].value,
            email: user.mail[0].value,
            // phone_number: user.phone[0].value,
            // roles:
            // company_name: subscriber.company_name[0] ? subscriber.company_name[0].value : 'none',
            // subscription_status: subscriber.subscription_status[0].value,
            // services: JSON.parse(subscriber.services[0].value),
            // user_bill_list: JSON.parse(subscriber.user_bill_list[0].value),
            // interests: JSON.parse(subscriber.user_interests[0].value),
            // government_interests: JSON.parse(subscriber.govt_user_interests[0].value),
          };
          thisArray.push(newObject);
        }
      });
      setAllSiteUsers(thisArray);
    }
  };

  const removeUserFromCompany = async (thisEmail, thisCompanyName) => {
    let thisPurpose = 'remove from company';
    
    const resourceData = {
      purpose: thisPurpose,
      subscriber_email: thisEmail,
      company: thisCompanyName
    };

    const url = `/hub_user`;
    const dataOptions = {
      method: 'POST',
      headers: new Headers({
        'Accept': 'application/vnd.api+json',
        'Content-Type': 'application/json'
      }),
      body: JSON.stringify(resourceData),
    };
    const data = await auth.fetchWithAuthentication(url, dataOptions);
    // const jsonDataParse = await data.json();
    setTableActionAlertsMessage("You have successfully removed this user from the specified company. If this was a mistake, readd the user as a member of the company. Otherwise, refresh the page to see the update(s)");
    setTableActionAlertsOn(true);
    setTableActionAlertsStatus("success");
  };

  const changeCheck = (variable, setThis) => {
    let thisValue = !variable;

    setThis(thisValue);
  };

  const grantAccessCompanySubscription = async (companyId) => {

    let thisPurpose = 'subscriptionChange';

    const dataToSend = {
      company_id: companyId,
      access: 'access',
      purpose: thisPurpose
    };

    const url = `/hub_user`;
    const dataOptions = {
      method: 'POST',
      headers: new Headers({
        'Accept': 'application/vnd.api+json',
        'Content-Type': 'application/json'
      }),
      body: JSON.stringify(dataToSend),
    };
    const data = await auth.fetchWithAuthentication(url, dataOptions);
    const jsonDataParse = await data.json();
    if (jsonDataParse.status === 200) {
      setTableActionAlertsMessage("You have successfully given this company access privileges. If this was a mistake, immediately remove access rights from the company. Otherwise, refresh the page to see the update(s)");
      setTableActionAlertsOn(true);
      setTableActionAlertsStatus("success");
    } else {
      setTableActionAlertsMessage("There was an error while attempting to give this company access privileges. Refresh the page, and try again");
      setTableActionAlertsOn(true);
      setTableActionAlertsStatus("error");
    }
  };

  const cancelCompanySubscription = async (companyId) => {

    let thisPurpose = 'subscriptionChange';

    const dataToSend = {
      company_id: companyId,
      access: 'none',
      purpose: thisPurpose
    };

    const url = `/hub_user`;
    const dataOptions = {
      method: 'POST',
      headers: new Headers({
        'Accept': 'application/vnd.api+json',
        'Content-Type': 'application/json'
      }),
      body: JSON.stringify(dataToSend),
    };
    const data = await auth.fetchWithAuthentication(url, dataOptions);
    const jsonDataParse = await data.json();
    if (jsonDataParse.status === 200) {
      setTableActionAlertsMessage("You have successfully removed this company's access privileges. If this was a mistake, immediately reinstate access rights for the company. Otherwise, refresh the page to see the update(s)");
      setTableActionAlertsOn(true);
      setTableActionAlertsStatus("success");
    } else {
      setTableActionAlertsMessage("There was an error while attempting to take away this company's access privileges. Refresh the page, and try again");
      setTableActionAlertsOn(true);
      setTableActionAlertsStatus("error");
    }
  };

  const recordAPayment = async (thisId) => {
    const dayConstructed = `${eventMonth}/${eventDay}`;

    let thisPurpose = 'record a payment';

    const dataToSend = {
      company_id: thisId,
      renewal_date: dayConstructed,
      purpose: thisPurpose
    };

    const url = `/hub_user`;
    const dataOptions = {
      method: 'POST',
      headers: new Headers({
        'Accept': 'application/vnd.api+json',
        'Content-Type': 'application/json'
      }),
      body: JSON.stringify(dataToSend),
    };
    const data = await auth.fetchWithAuthentication(url, dataOptions);
    const jsonDataParse = await data.json();
    if (jsonDataParse.status === 200) {
      setTableActionAlertsMessage("You have successfully recorded the date of this company's first payment. Otherwise, refresh the page to see the update(s)");
      setTableActionAlertsOn(true);
      setTableActionAlertsStatus("success");
    } else {
      setTableActionAlertsMessage("There was an error while attempting to record the date of this company's first payment. Refresh the page, and try again");
      setTableActionAlertsOn(true);
      setTableActionAlertsStatus("error");
    }
  };

  const getAllCompanies = async() => {
    let thisPurpose = 'fetch companies';

    const resourceData = {
      purpose: thisPurpose
    };

    const url = `/hub_user`;
    const dataOptions = {
      method: 'POST',
      headers: new Headers({
        'Accept': 'application/vnd.api+json',
        'Content-Type': 'application/json'
      }),
      body: JSON.stringify(resourceData),
    };
    const data = await auth.fetchWithAuthentication(url, dataOptions);
    const jsonDataParse = await data.json();

    if (jsonDataParse.status === 200 && jsonDataParse.company) {
      let thisObj = Object.values(jsonDataParse.company);
      let thisArray = [];
      // eslint-disable-next-line array-callback-return
      thisObj.map((thisCompany) => {
        let newObject = {
          id: thisCompany.id[0].value,
          company_size: thisCompany.company_size[0].value,
          company_name: thisCompany.company_name[0].value,
          admin_email: thisCompany.admin_email[0].value,
          admin_phone: thisCompany.admin_phone[0].value,
          contract_status: thisCompany.contract_status[0].value,
          subscription_renewal: thisCompany.payment_renewal_date[0].value,
          services: JSON.parse(thisCompany.services_requested[0].value),
          // subscription_status: thisCompany.subscription_status[0].value,
          employee_emails: JSON.parse(thisCompany.employee_emails[0].value),
        };
        thisArray.push(newObject);
      });
      setAllCompanies(thisArray);
    } else {
      
    }
  };
  
  const getAllSubscribers = async() => {
    const url = `/hub_subscriber`;
    const dataOptions = {
      method: 'GET',
      headers: new Headers({
        'Accept': 'application/vnd.api+json',
        'Content-Type': 'application/json'
      }),
    };
    const data = await auth.fetchWithAuthentication(url, dataOptions);
    const jsonDataParse = await data.json();

    if (jsonDataParse.status === 200 && jsonDataParse.subscribers) {
      let thisObj = Object.values(jsonDataParse.subscribers);
      let thisArray = [];
      
      // eslint-disable-next-line array-callback-return
      thisObj.map((subscriber) => {
        let newObject = {
          id: subscriber.id[0].value,
          subscriber_email: subscriber.subscriber_email[0].value,
          subscriber_phone: subscriber.subscriber_phone[0].value,
          company_name: subscriber.company_name[0] ? subscriber.company_name[0].value : 'none',
          subscription_status: subscriber.subscription_status[0].value,
          services: JSON.parse(subscriber.services[0].value),
          // user_bill_list: JSON.parse(subscriber.user_bill_list[0].value),
          // interests: JSON.parse(subscriber.user_interests[0].value),
          // government_interests: JSON.parse(subscriber.govt_user_interests[0].value),
        };
        thisArray.push(newObject);
      });
      setAllUsers(thisArray);
    } else {
      
    }
  };

  const userColumns = useMemo(
    () => [
      {
        header: 'Id',
        accessorKey: 'id',
      },
      {
        header: 'Email',
        accessorKey: 'subscriber_email',
      },
      {
        header: 'Phone',
        accessorKey: 'subscriber_phone',
      },
      {
        header: 'Company Name',
        accessorKey: 'company_name',
      },
      {
        header: 'Access',
        accessorKey: 'subscription_status',
      },
      {
        header: 'Services',
        accessorKey: 'services',
      },
    ],
    [],
  );

  const siteUserColumns = useMemo(
    () => [
      {
        header: 'Id',
        accessorKey: 'id',
      },
      {
        header: 'Email',
        accessorKey: 'email',
      },
      // {
      //   header: 'Phone',
      //   accessorKey: 'phone_number',
      // },
      // {
      //   header: 'Company Name',
      //   accessorKey: 'company_name',
      // },
      // {
      //   header: 'Access',
      //   accessorKey: 'subscription_status',
      // },
      // {
      //   header: 'Services',
      //   accessorKey: 'services',
      // },
    ],
    [],
  );

  const businessColumns = useMemo(
    () => [
      {
        header: 'Id',
        accessorKey: 'id',
      },
      {
        header: 'Admin Email',
        accessorKey: 'admin_email',
      },
      {
        header: 'Admin Phone',
        accessorKey: 'admin_phone',
      },
      {
        header: 'Company Name',
        accessorKey: 'company_name',
      },
      // {
      //   header: 'Access',
      //   accessorKey: 'subscription_status',
      // },
      {
        header: 'Services',
        accessorKey: 'services',
      },
    ],
    [],
  );

  const confirmDeleteId = (thisId) => {
    setDeleteUserId(thisId);
    setConfirmUseDeletion(true);
  };

  const closeUserDeletionConfirm = () => {
    setConfirmUseDeletion(false);
  };

  useEffect(() => {
    if (!allCompanies || allCompanies.length === 0) {
      getAllCompanies();
    }
    if (!allUsers || allUsers.length === 0) {
      getAllSubscribers();
    }
    if (!allSiteUsers || allSiteUsers.length <= 0) {
      getAllUsers();
    }
  });

  return (
    <Box sx={root}>
      <DashboardHome currentPage="usermanagement" />
      <Box sx={{ display: 'flex', flexDirection: 'column', width: '95%' }}>
        <Button href="/create-user" sx={{ width: '10%' }}>
          Create A User
        </Button>
        <Fade
          in={tableActionAlertsOn}
          timeout={500}
          unmountOnExit
          sx={{ marginBottom: '2%', width: '95%', alignSelf: 'center', position: 'sticky', top: 0, }}
          onEnter={() => {
            window.setTimeout(() => { setTableActionAlertsOn(false); }, 10000);
          }}
        >
          <Alert severity={tableActionAlertsStatus}>
            {tableActionAlertsMessage}
          </Alert>
        </Fade>
        {(!allUsers || allUsers.length <= 0) && (
          <Typography sx={{ color: theme.palette.secondary.main, marginLeft: '5.5%', fontSize: '2rem', marginTop: '4%', width: '100%' }}>
            Retrieving Users
          </Typography>
        )}
        {(allUsers && allUsers.length > 0) && (
          <Box sx={{ marginTop: '1%', width: '100%', alignSelf: 'center' }}>
            <Typography sx={{ fontSize: '2.5rem', color: theme.palette.secondary.main }}>
              Subscribers
            </Typography>
            <MaterialReactTable
              columns={userColumns}
              data={allUsers}
              enableRowActions
              renderRowActions={(row) => (
                <Box>
                  <IconButton onClick={() => setSusbcriberAndOpenDialog(row.row.original.id)}>
                    <EditIcon />
                  </IconButton>
                  {/* <IconButton onClick={() => setDeleteCompanyId(row.row.original.id)}>
                    <DeleteIcon />
                  </IconButton> */}
                </Box>
              )}
              positionActionsColumn="last"
            />
          </Box>
        )}
        {(allCompanies && allCompanies.length > 0) && (
          <Box sx={{ marginTop: '1%', width: '100%', alignSelf: 'center' }}>
            <Typography sx={{ color: theme.palette.secondary.main, fontSize: '2.5rem' }}>
              Companies
            </Typography>
            <MaterialReactTable
              columns={businessColumns}
              data={allCompanies}
              enableRowActions
              renderRowActions={(row) => (
                <Box>
                  <IconButton onClick={() =>setBusinessAndOpenDialog(row.row.original.id)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton onClick={() => grantAccessCompanySubscription(row.row.original.id)}>
                    <CheckIcon />
                  </IconButton>
                  <IconButton onClick={() => cancelCompanySubscription(row.row.original.id)}>
                    <ClearIcon />
                  </IconButton>
                  <IconButton onClick={() => {
                    setDeleteCompanyId(row.row.original.id);
                    setConfirmCompanyDeletion(true);
                  }}>
                    <DeleteIcon />
                  </IconButton>
                </Box>
              )}
              positionActionsColumn="last"
            />
          </Box>
        )}
        {(allSiteUsers && allSiteUsers.length > 0) && (
          <Box sx={{ marginTop: '1%', width: '100%', alignSelf: 'center' }}>
            <Typography sx={{ fontSize: '2.5rem', color: theme.palette.secondary.main }}>
              Users
            </Typography>
            <MaterialReactTable
              columns={siteUserColumns}
              data={allSiteUsers}
              // enableRowActions
              // renderRowActions={(row) => (
              //   <Box>
              //     <IconButton onClick={() => setSusbcriberAndOpenDialog(row.row.original.id)}>
              //       <EditIcon />
              //     </IconButton>
              //     <IconButton onClick={() => setDeleteCompanyId(row.row.original.id)}>
              //       <DeleteIcon />
              //     </IconButton>
              //   </Box>
              // )}
              // positionActionsColumn="last"
            />
          </Box>
        )}
      </Box>
      <MobileDashboard />
      {(oneCompany) && <Dialog
        open={openBusiness}
        onClose={closeBusinessDialog}
      >
        <DialogTitle>
          {`Company Information for ${oneCompany.company_name[0].value}`}
        </DialogTitle>
        <DialogContent sx={{ display: 'flex', flexDirection: 'column' }}>
          <Box sx={subscriberDetailsContainer}>
            <Typography>
              Administrator Email Address
            </Typography>
            <Typography sx={{ color: 'darkgray' }}>     
              {oneCompany.admin_email[0].value}
            </Typography>
          </Box>
          <Box sx={subscriberDetailsContainer}>
            <Typography>
              Administrator Phone Number
            </Typography>
            <Typography sx={{ color: 'darkgray' }}>     
              {oneCompany.admin_phone[0].value}
            </Typography>
          </Box>
          {/* <Box sx={subscriberDetailsContainer}>
            <Typography>
              Subscription Status
            </Typography>
            <Typography sx={{ color: 'darkgray' }}>     
              {oneCompany.subscription_status[0].value}
            </Typography>
          </Box> */}
          <Box sx={subscriberDetailsContainer}>
            <Typography>
              Date of Company's First Payment
            </Typography>
            <Typography sx={{ color: 'darkgray' }}>     
              {oneCompany.payment_renewal_date[0].value !== 'no payment' ? new Date(oneCompany.payment_renewal_date[0].value * 1000).toLocaleDateString("en-US") : 'no payment yet'}
            </Typography>
            <IconButton
              sx={removeFromCompanyButton}
              onClick={() => {
                recordAPayment(oneCompany.id[0].value);
              }}
              disabled={(eventDay !== "0" && eventMonth !== "0") ? false : true}
            >
              Record First Company Payment
            </IconButton>
          </Box>
          <Box sx={subscriberDetailsContainer}>
            <Typography>
              Requested Service Package
            </Typography>
            <Typography sx={{ color: 'darkgray' }}>     
              {oneCompany.services_requested[0].value}
            </Typography>
            <Button
              onClick={() => {
                setCompanyServicesBeingAltered(true);
              }}
            >
              Edit Company Services
            </Button>
          </Box>
          <Box sx={subscriberDetailsContainer}>
            <Typography>
              Members Included On Plan
            </Typography>
            {/* <Typography sx={{ color: 'darkgray' }}> */}
              {
                (oneCompany.employee_emails) && (
                // eslint-disable-next-line array-callback-return
                JSON.parse(oneCompany.employee_emails[0].value)).map((obj) => {
                  return (
                    <Box
                      sx={{ display: 'flex', flexDirection: 'row',
                        alignItems: 'center',
                      }}
                    >
                      <Typography>
                        { `${obj.email}   ${obj.phone}` }
                      </Typography>
                      <IconButton onClick={() => removeUserFromCompany(obj.email, oneCompany.company_name[0].value)}>
                        <Typography>
                          Deactive User
                        </Typography>
                      </IconButton>
                    </Box>
                  )
                })
              }
            {/* </Typography> */}
          </Box>
        </DialogContent>z
        <DialogActions>
          <Button onClick={closeBusinessDialog}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      }
      {(oneUser) && 
      <Dialog
        open={editViewSubscriber}
        onClose={closeSubscriberDialog}
      >
        <DialogTitle>
          {`User Information for ${oneUser.subscriber_email[0].value}`}
        </DialogTitle>
        <DialogContent sx={{ display: 'flex', flexDirection: 'column' }}>
          <Box sx={subscriberDetailsContainer}>
            <Typography>
              Email Address
            </Typography>
            <Typography sx={{ color: 'darkgray' }}>     
              {oneUser.subscriber_email[0].value}
            </Typography>
          </Box>
          <Box sx={subscriberDetailsContainer}>
            <Typography>
              Phone Number
            </Typography>
            <Typography sx={{ color: 'darkgray' }}>     
              {oneUser.subscriber_phone[0].value}
            </Typography>
          </Box>
          <Box sx={subscriberDetailsContainer}>
            <Typography>
              Subscription Status
            </Typography>
            <Typography sx={{ color: 'darkgray' }}>     
              {oneUser.subscription_status[0].value}
            </Typography>
          </Box>
          <Box sx={subscriberDetailsContainer}>
            <Typography>
              Current Company
            </Typography>
            <Typography sx={{ color: 'darkgray' }}>     
              {oneUser.company_name[0].value}
            </Typography>
            {}
            <IconButton
              sx={removeFromCompanyButton}
              onClick={() => removeUserFromCompany(oneUser.subscriber_email[0].value, oneUser.company_name[0].value)}
              disabled={(oneUser.company_name[0].value !== null && oneUser.company_name[0].value !== 'unassigned' && oneUser.company_name[0].value !== 'none') ? false : true }
            >
              Remove This User From This Company
            </IconButton>
          </Box>
          <Box sx={subscriberDetailsContainer}>
            <Typography>
              Requested Service Package
            </Typography>
            <Typography sx={{ color: 'darkgray' }}>     
              {oneUser.services[0].value}
            </Typography>
          </Box>
          <Box sx={subscriberDetailsContainer}>
            {(oneUser.company_name[0].value === null || oneUser.company_name[0].value === 'unassigned' || oneUser.company_name[0].value === 'none') &&
              <FormControl color="secondary" sx={{ width: '100%', margin: '1% 0%' }} variant="standard">
                <InputLabel>Companies To Choose From</InputLabel>
                <Select
                  onChange={handleCompanySelectionForUserAddOn}
                  value={companyToAddUserTo}
                >
                  {
                  // eslint-disable-next-line array-callback-return
                  allCompanies.map((thisObject) => {
                    if (thisObject.employee_emails.length < thisObject.company_size)
                    {
                      return (
                        <MenuItem sx={{ color: theme.palette.secondary.main }} value={thisObject.company_name}>{thisObject.company_name}</MenuItem>
                      )
                    }
                  })
                  }
                </Select>
              </FormControl>
            }
            <IconButton
              onClick={() => {
                if (oneUser.company_name[0].value === null || oneUser.company_name[0].value === 'unassigned') {
                  addUserToCompany(oneUser.subscriber_email[0].value, oneUser.subscriber_phone[0].value, companyToAddUserTo);
                }
              }}
              sx={removeFromCompanyButton}
              style={{ backgroundColor: (oneUser.company_name[0].value !== null && oneUser.company_name[0].value !== 'unassigned') ? 'lightgray' : theme.palette.secondary.main }}
              disabled={(oneUser.company_name[0].value !== null && oneUser.company_name[0].value !== 'unassigned') ? true : false}
            >
              Add This User To This Company
            </IconButton>
          </Box>
        </DialogContent>
      <DialogActions>
        <Button onClick={closeSubscriberDialog}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
    }
      <Dialog
        open={confirmUserDeletion}
        onClose={closeUserDeletionConfirm}
      >
        <DialogTitle sx={{ color: 'black' }}>
          Are You Sure That You Want To Delete This User?
        </DialogTitle>
        <DialogContent sx={{ color: 'black' }}>
          Are You Sure That You Want To Delete This User Account? This Action Cannot Be Reversed.
        </DialogContent>
        <DialogActions>
          <Button variant="contained" sx={{ color: 'white' }} onClick={() => { if (deleteUserId > 0) { deleteOneUser(deleteUserId); }}}>
            I'm Sure
          </Button>
          <Button onClick={closeUserDeletionConfirm}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={confirmCompanyDeletion}
        onClose={closeCompanyDeletionConfirm}
      >
        <DialogTitle sx={{ color: 'black' }}>
          Are You Sure That You Want To Delete This Company?
        </DialogTitle>
        <DialogContent sx={{ color: 'black' }}>
          Are You Sure That You Want To Delete This Company Account? This Action Cannot Be Undone.
        </DialogContent>
        <DialogActions>
          <Button variant="contained" sx={{ color: 'white' }} onClick={() => { if (deleteCompanyId > 0) { deleteACompany(deleteCompanyId); }}}>
            I'm Sure
          </Button>
          <Button onClick={closeCompanyDeletionConfirm}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={companyServicesBeingAltered} onClose={handleCompanyServicesChangeClose}>
        <Typography>
          Which Services Would You Like This Company To Have?
        </Typography>
        <FormGroup sx={userServicesFields}>
          <FormControlLabel control={<Checkbox checked={(boardService || billService)} onChange={() => {changeCheck(boardService, setBoardService)}} />} label="Board Watch Service" />
          <FormControlLabel control={<Checkbox checked={governmentService} onChange={() => {changeCheck(governmentService, setGovernmentService)}} />} label="Government Watch Service" />
          <FormControlLabel control={<Checkbox checked={billService} onChange={() => {changeCheck(billService, setBillService)}} />} label="Bill Watch Service" />
        </FormGroup>
        <Button
          onClick={() => {
            changeServicesForCompany(oneCompany.id[0].value);
          }}
        >
          Save
        </Button>
      </Dialog>
    </Box>
  );
};

export default ManageUsers;
