import { useState } from 'react';

export default function useUser() {
  const getUser = () => {
    const userToken = localStorage.getItem('drupal-oauth-token');
    if (userToken) {
      return true;
    } else {
      return false;
    }
  };

  const [user, setUser] = useState(getUser());

  const setAccess = () => {
    const access = getUser();
    if (access) {
      setUser(true);
    } else {
      setUser(false);
    }
  };

  return {
    setUser: setAccess,
    user
  }
}