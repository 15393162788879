import React from 'react';
import {
  BrowserRouter, Route, Routes, Navigate,
} from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import useUser from './utils/useUser';
import ManageUsers from './AdminPages/ManageUsers';
import AboutUs from './PublicPages/AboutUs';
// import BillWatchPage from './RestrictedAccess/BillWatchPage';

// Lazy Imports
const Login = React.lazy(() => import('./LoginRegister/Login/Login'));
// const HomePage = React.lazy(() => import('./PublicPages/HomePage'));
const Register = React.lazy(() => import('./LoginRegister/Register/Register'));
// const Wait = React.lazy(() => import('./LoginRegister/Register/ComingSoon'));
const Services = React.lazy(() => import('./PublicPages/Services'));
const Contact = React.lazy(() => import('./PublicPages/Contact'));
const UserSettings = React.lazy(() => import('./RestrictedAccess/UserSettings'));
const CreateNewUser = React.lazy(() => import('./AdminPages/CreateNewUser'));
const CreateBoardWatch = React.lazy(() => import('./EmployeePages/CreateBoardWatch'));
const BoardWatch = React.lazy(() => import('./RestrictedAccess/BoardWatch'));
const ResetPassword = React.lazy(() => import('./LoginRegister/Login/ResetPassword'));
const GovernmentWatch = React.lazy(() => import('./RestrictedAccess/GovernmentWatch'));
const CreateGovernmentWatch = React.lazy(() => import('./EmployeePages/CreateGovernmentWatch'));
const BillWatch = React.lazy(() => import('./RestrictedAccess/BillWatch'));
const CreateBillWatch = React.lazy(() => import('./EmployeePages/CreateBillWatch'));
const UsefulTools = React.lazy(() => import('./EmployeePages/UsefulTools'));
const BillWatchPage = React.lazy(() => import('./RestrictedAccess/BillWatchPage'));

function App() {
  const { user, setUser } = useUser();

  const PrivateRoute = ({ children }) => (user ? children : <Navigate replace to="/" />);

  const protectedRoutes = (
    <Routes>
      <Route path="/createboardwatch" element={<PrivateRoute><CreateBoardWatch /></PrivateRoute>} loginPath="/"/>
      <Route path="/createbillwatch" element={<PrivateRoute><CreateBillWatch /></PrivateRoute>} loginPath="/"/>
      <Route path="/createnewuser" element={<PrivateRoute><CreateNewUser /></PrivateRoute>} loginPath="/"/>
      <Route path="/creategovernmentwatch" element={<PrivateRoute><CreateGovernmentWatch /></PrivateRoute>} loginPath="/"/>
      <Route path="/governmentwatch" element={<PrivateRoute><GovernmentWatch /></PrivateRoute>} loginPath="/"/>
      <Route path="/boardwatch" element={<PrivateRoute><BoardWatch /></PrivateRoute>} loginPath="/"/>
      <Route path="/billwatch" element={<PrivateRoute><BillWatch /></PrivateRoute>} loginPath="/"/>
      <Route path="/settings" element={<PrivateRoute><UserSettings user={user} setUser={setUser} /></PrivateRoute>} loginPath="/"/>
      <Route path="/bill/:billId" element={<BillWatchPage />} />
      <Route path="/resetpassword" element={<Navigate to="/settings"/>} />
      <Route path="/register59235070" element={<Register />} />
      <Route path="/aboutus" element={<AboutUs />} />
      <Route path="/tools" element={<UsefulTools />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/services" element={<Services />} />
      <Route path="/create-user" element={<PrivateRoute><CreateNewUser/></PrivateRoute>} loginPath="/"/>
      <Route path="/manage" element={<ManageUsers />} loginPath="/"/>
      <Route path="/" element={(!user) ?  <Login user={user} setUser={setUser} /> : <Navigate to="settings"/>} />
    </Routes>
  );

  const publicRoutes = (
    <Routes>
      <Route path="/bill/:billId" element={<BillWatchPage />} />
      <Route path="/settings" element={<Navigate to="/" />} />
      <Route path="/resetpassword" element={<ResetPassword />} />
      <Route path="/aboutus" element={<AboutUs />} />
      <Route path="/register59235070" element={<Register />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/boardwatch" element={<PrivateRoute><BoardWatch /></PrivateRoute>} loginPath="/"/>
      <Route path="/billwatch" element={<PrivateRoute><BillWatch /></PrivateRoute>} loginPath="/"/>
      <Route path="/services" element={<Services />} />
      <Route path="/" element={<Login user={user} setUser={setUser}/>}/>
    </Routes>
  );

  if (user) {
    return (
      <React.Suspense fallback={<center><CircularProgress /></center>}>
        <BrowserRouter>
          {protectedRoutes}
        </BrowserRouter>
      </React.Suspense>
    );
  } else {
    return (
      <React.Suspense fallback={<center><CircularProgress /></center>}>
        <BrowserRouter>
          {publicRoutes}
        </BrowserRouter>
      </React.Suspense>
    );
  }
}

export default App;
