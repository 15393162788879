import { createTheme } from '@mui/material/styles';
  
const theme = createTheme({
  palette: {
    primary: {
      main: 'rgb(106, 172, 221)',
    },
    secondary: {
      main: 'rgb(28, 29, 57)',
      secondary: 'rgb(239, 80, 98)',
    },
    text: {
      primary: 'rgb(106, 172, 221)',
      secondary: 'black',
    },
  },

  // components: {
  //   MuiCssBaseline: {
  //     styleOverrides: {
  //       '*::-webkit-scrollbar': {
  //         width: '0.4em',
  //         height: '0.4em',
  //         overflow: 'overlay',
  //       },
  //       '*::-webkit-scrollbar *': {
  //         background: 'transparent',
  //       },
  //       '*::-webkit-scrollbar-track': {
  //         display: 'none',
  //       },
  //       '*::-webkit-scrollbar-thumb': {
  //         background: '#a3c755',
  //         borderRadius: '10px',
  //       },
  //     },
  //   },
  // }
});

export default theme;
