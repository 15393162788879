import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box, Button, IconButton, Drawer,
  Divider, List, ListItem, ListItemText,
  Link,
} from '@mui/material';
import theme from '../theme';
import { getAuthClient } from '../utils/auth';
import TuneIcon from '@mui/icons-material/Tune';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import GavelIcon from '@mui/icons-material/Gavel';

const MobileDashboard = (props) => {
  const auth = getAuthClient();
  const [currentUser, setCurrentUser] = useState(null);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const getUser = async () => {
    if (!currentUser) {
      const url = `/hub_user`;
      const dataOptions = {
        method: 'GET',
        headers: new Headers({
          'Accept': 'application/vnd.api+json',
          'Content-Type': 'application/json'
        }),
      };

      const data = await auth.fetchWithAuthentication(url, dataOptions);
      const jsonData = await data.json();
      setCurrentUser(jsonData.current_user);
    }
  };

  useEffect(() => {
    if (!currentUser) {
      getUser();
    }
  });

  const mobileDash = {
    position: 'sticky',
    bottom: '0px',
    width: '100%',
    display: 'none',
    zIndex: 100,
    backgroundColor: 'white',
    borderTopStyle: 'solid',
    borderTopColor: 'aliceblue',
    [theme.breakpoints.down(980)]: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-around',
      alignItems: 'center',
      height: '13vh',
    },
    [theme.breakpoints.down(650)]: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-around',
      alignItems: 'center',
    },
  };
  const mobileTabContainer = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  };
  const mobileTabStyle = {
    color: theme.palette.primary.main,
    [theme.breakpoints.down(650)]: {
      fontSize: '0.9rem',
    },
    [theme.breakpoints.down(563)]: {
      fontSize: '0.8rem',
    },
    [theme.breakpoints.down(500)]: {
      fontSize: '0.69rem',
    },
    [theme.breakpoints.down(430)]: {
      fontSize: '0.66rem',
    },
    [theme.breakpoints.down(420)]: {
      fontSize: '0.58rem',
    },
  };
  const dockerAppearance = {
    display: 'none',
    [theme.breakpoints.down(1170)] : {
      display: 'flex',
    },
    '& .MuiPaper-root': {
      width: '30%',
      backgroundColor: theme.palette.primary.main,
      [theme.breakpoints.down(940)]: {
        width: '45%',
      },
    },
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
  <Box sx={mobileDash}>
    <Box sx={mobileTabContainer}>
      <IconButton onClick={() => {handleDrawerOpen();}} sx={{ padding: '0px', marginTop: '1%' }}>
        <TuneIcon sx={{ color: theme.palette.primary.main }} />    
      </IconButton>
      <Button onClick={() => {handleDrawerOpen();}} sx={mobileTabStyle} style={{ color: theme.palette.primary.main }}>
        Settings
      </Button>
    </Box>
    
    <Box sx={mobileTabContainer}>
      <IconButton href="/boardwatch" sx={{ padding: '0px', marginTop: '1%' }}>
        <CalendarMonthIcon sx={{ color: theme.palette.primary.main }} />
      </IconButton>
      <Button href="/boardwatch" sx={mobileTabStyle}>
        Board Watch
      </Button>
    </Box>
    
    <Box sx={mobileTabContainer}>
      <IconButton href="/billwatch" sx={{ padding: '0px', marginTop: '1%' }}>
        <GavelIcon sx={{ color: theme.palette.primary.main }} />
      </IconButton>
      <Button href="/billwatch" sx={mobileTabStyle}>
        Bill Watch
      </Button>
    </Box>
    
    <Box sx={mobileTabContainer}>
      <IconButton href="/governmentwatch" sx={{ padding: '0px', marginTop: '1%' }}>
        <AccountBalanceIcon sx={{ color: theme.palette.primary.main }} />
      </IconButton>
      <Button href="/governmentwatch" sx={mobileTabStyle}>
        Government Watch
      </Button>
    </Box>
    <Drawer
      sx={dockerAppearance}
      variant="temporary"
      anchor="left"
      open={open}
    >
      <div>
        <IconButton onClick={handleDrawerClose}>
          <ChevronLeftIcon />
        </IconButton>
      </div>
      <Divider />
      <List>
        <ListItem button component={Link} key="Settings" href="/">
          <ListItemText sx={{ color: 'white' }}>
            My Settings
          </ListItemText>
        </ListItem>
        {(currentUser && currentUser.roles && (currentUser.roles.includes("employee") || currentUser.roles.includes("admin"))) && (
          <Box>
            <ListItem button component={Link} key="Create Board Watch" onClick={()=>navigate("/createboardwatch")}>
              <ListItemText sx={{ color: 'white' }}>
                Add Board Watch
              </ListItemText>
            </ListItem>
            <ListItem button component={Link} key="Create Bill Watch" onClick={()=>navigate("/createbillwatch")}>
              <ListItemText
                href="/createbillwatch"
                sx={{ color: 'white' }}
              >
                Add Bill Watch
              </ListItemText>
            </ListItem>
            <ListItem component={Link} key="Create Government Watch" onClick={()=>navigate("/creategovernmentwatch")}>
              <ListItemText
                href="/creategovernmentwatch"
                sx={{ color: 'white' }}
              >
                Add Government Watch
              </ListItemText>
            </ListItem>
            <ListItem component={Link} key="Manage Data" onClick={()=>navigate("/manage")}>
              <ListItemText
                href="/manage"
                sx={{ color: 'white' }}
              >
                Manage Users
              </ListItemText>
            </ListItem>
          </Box>
        )}
      </List>
      <Divider />
      <List>
        <ListItem component={Link}
          onClick={ 
            ()=>{
              auth.logout();
              navigate("/contact");
            }
          }
          >
          <ListItemText sx={{ color: 'white' }}>
            Log Out
          </ListItemText>
        </ListItem>
      </List>
    </Drawer>
  </Box>
  );
};

export default MobileDashboard;
